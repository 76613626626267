.left-sidebar {
  background: #e1e1e1 !important;
  top: 50px !important;
  padding: 0;
  height: calc(100% - 50px);

  .sidebar-nav > ul > li {
    margin: 8px 0;
    a {
      padding: 8px 5px 8px 5px !important;
      text-align: center;
      width: auto !important;
      > img {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto;
      }
      .badge {
        position: absolute;
        right: 6px;
        top: 7px;
      }
      span {
        font-size: 14px;
        margin: 0;
        display: block;
      }
    }
    &:hover > a {
      background: #f3f3f3 !important;
      border: none !important;
      font-weight: 500 !important;
      color: #00549e !important;
      svg path {
        fill: #00549e;
      }
    }
    &:last-child:hover,
    &.bottom-ul:hover {
      ul {
        top: auto !important;
        bottom: 0;
      }
    }
  }
  .sidebar-nav ul li a.active {
    background: #f3f3f3;
    color: #202124;
    font-weight: 700;
  }
}
.fixed-layout .page-wrapper {
  padding-top: 51px;
  margin-left: 78px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f3f3f3;
}
.sidebar-nav > ul > li.active {
  width: 100% !important;
}
.sidebar-nav > ul > li > a i {
  font-size: 24px !important;
  display: block;
  margin: 0 auto;
}
.fixed-layout .left-sidebar {
  position: absolute !important;
}
.fixed-layout .admin.left-sidebar {
  width: 108px !important;
  .sidebar-nav {
    ul {
      li {
        // background-color: red;
        svg {
          margin: 0px !important;
        }
        &.hover:hover {
          a span {
            display: block !important;
          }
        }
        &:hover {
          i {
            color: #00549e;
          }
        }
        a:hover {
          i {
            color: #00549e !important;
          }
        }
      }
    }
  }
}
.fixed-layout .page-wrapper {
  margin-left: 108px !important;
}
[class^="ti-"] {
  font-family: themify !important;
}
@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    top: 0;
    left: 100%;
    padding-bottom: 0;
    li {
      margin-top: 4px;
      margin-bottom: 5px !important;
      a {
        font-size: 14px;
        padding: 8px 5px 8x 5px !important;
        &:hover {
          color: #00549e !important;
        }
      }
    }
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
    display: none;
  }
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
    display: block;
  }
}

.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
  font-weight: 500 !important;
  color: #00549e !important;
}
.fa,
.far,
.fas {
  font-family: "FontAwesome" !important;
}
.icon-screen-desktop {
  font-family: "simple-line-icons" !important;
}
.left-sidebar .sidebar-nav ul li ul li a {
  padding: 8px 5px 8px 5px !important;
}
.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu:hover {
  color: #00549e !important;
}
