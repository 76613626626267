.large-spinner .spinner-border {
    width: 3rem;
    height: 3rem;
  }

.zIndexHigh{
    width: 100vw;
    min-height: 120vh;
    z-index: 3000;
    position: fixed;
}