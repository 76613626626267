.page-title-row {
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0 5px;
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #222222;
  }
  .breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #222222;
      margin: 0;
      padding: 0;
      &.active {
        color: #00549e !important;
        font-weight: 600;
      }
      a {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem !important;
}
.filter-box-1 {
  margin: 10px 0 0 0;
  font-family: "Inter", sans-serif;
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    appearance: auto;
  }
  .form-group {
    margin-bottom: 10px;
    label {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      display: block;
      color: #222222;
      margin-bottom: 3px;
    }
  }
  .form-control {
    min-height: 32px;
    border: 1px solid #dadada;
    border-radius: 0;
    padding: 5px 12px !important;
    line-height: 20px;
    height: 32px !important;
  }
  .btn-block {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px;
    text-align: center;
    border-radius: 0 !important;
    height: 32px !important;
    box-shadow: none;
    border: none;
    margin: 20px 0 0 0 !important;
  }
  .classButton {
    background: #00549e;
    border: 1px solid #dadada;
  }
  .outlineColor {
    background: #ffffff;
    border: 1.5px solid #00549e;
    color: #00549e;
  }
}
.line-1 {
  border: 1px solid #d9d9d9;
  width: 100%;
  margin: 2px 10px 8px 10px;
  height: 1px;
}
.card-with-table {
  font-family: "Inter", sans-serif !important;
  background: #fff;
  padding: 15px 15px;
  // box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.55);
  // border-radius: 10px;
  .table-bordered {
    border: none;
  }
  table.table thead {
    box-shadow: none;
  }
  .table-dark {
    background: #808184 !important;
    th {
      padding: 6px 10px 6px 10px !important;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: #ffffff;
      background: #808184 !important;
      font-family: "Inter", sans-serif !important;
    }
  }
  .table {
    box-shadow: none;
    tbody {
      tr {
        td {
          padding: 7px 10px 7px 10px !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
        }
      }
    }
  }

  .table tbody tr:nth-of-type(odd) {
    background: #fff;
  }
  .table tbody tr:nth-of-type(even) {
    background: #f5f5f5;
  }
}
.copy-right {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.07em;
  color: #575757;
  text-align: right;
}
.btn-box-inline {
  display: flex;
  justify-content: space-between;
  .btn {
    margin-left: 27px !important;
  }
}
.collektoTable .pagination {
  margin: 0 !important;
}
ul.pagination li a {
  padding: 4px 8px !important;
}
.inline-1 {
  display: flex;
  align-items: center;
  .btn-alert {
    margin-right: 10px;
    border: none;
  }
}
.search-bar {
  min-height: 33px !important;
  height: 33px !important;
  line-height: 20px;
  padding: 5px 14px !important;
}

.alertCount {
  color: blue;
  margin: 5%;
  opacity: 0.6 !important;
}

/* Styling for the alertCount2 span */
.alertCount2 {
  position: absolute; /* Position the span relative to its closest positioned ancestor (.profile-pic-container) */
  top: 0; /* Position at the top of the container */
  right: 0; /* Position at the right side of the container */
  background-color: red; /* Set the background color for the alert count */
  color: white; /* Set the text color for the alert count */
  font-size: 8px; /* Set the font size for the alert count */
  padding: 2px 4px; /* Add padding to the span for better visibility */
  border-radius: 50%; /* Add border-radius to make it circular */
}

.modal-dialog-header {
  height: 95%;
  width: 100%;
  overflow: scroll;
}
.shadowclass {
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  width: 45%;
  border-radius: 5px;
}

.marginLeftClass {
  margin-left: 1%;
}

.setCard {
  // padding: 10%;
  // padding-top: 20%;
}

.cardWidth {
  width: 100%;
  border-radius: 5px;
}
