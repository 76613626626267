@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
.scroll-sidebar {
  height: 100vh;
  width: 100%;
}
.left-sidebar {
  box-shadow: none;
  overflow-y: unset;
  bottom: 0;
  padding: 0;
  top: 50px !important;
  width: 230px !important;
  .sidebar-nav {
    background: #e1e1e1 !important;
    padding: 5px 0 0;
    ul {
      li {
        margin-bottom: 7px;
        margin-top: 7px;
        a {
          font-family: "Inter", sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #575757;
          padding: 10px 10px 10px 12px;
          border-left: 0;
          svg{
            margin-right: 20px;
          }
          img {
            display: inline-block;
            width: 24px;
          }
          i {
            font-size: 24px;
            color: #575757;
            font-weight: 400;
            min-width: 24px;
          }
        }
        &.active {
          a {
            background: #f3f3f3;
            color: #00549E;
            font-weight: 500;
            svg path {
              fill: #00549e;
            }
          }
        }
        &.manager-siderbar-link{
          a{
            i{
              display: inline-block;
              margin-right: 15px;
            }
            span{
              display: inline-block !important;
            }
          }
        }
      }
    }
  }
  &:hover {
    overflow-y: unset;
  }
}
.fixed-layout .page-wrapper-landing {
  margin-left: 230px !important;
}
.fixed-layout .admin.page-wrapper {
  margin-left: 108px !important;
}
