@import url('https://rsms.me/inter/inter.css');

/* body {
    font-family: 'Inter' !important;
} */


/* .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 17.66667%;
} */

.form-group label {
    display: block;
}

.bg-hr{
    background-color: #D9D9D9;
}

.bg-header {
    background-color: #C7C7C7;
}

.bg-sidemenu {
    background-color: #DADADA;
}

.color-icons-sidemenu {
    color: #222222;
}

.col-md-12.collektoTable.active-loan-table {
    padding-top: 0px;
}

.bg-color-heading {
    background-color: #00549E;
}

.bg-retain-button {
    background-color: #E91010;
    color: white;
    font-weight: bolder;
    border: none;
    font-size: 8px;
}

.heading-blue {
    color: #00549E;
}

.text-light-blue {
    color: rgba(0, 84, 158, 0.5);
}

.card-headers {
    background-color: white;
}


.title-small-call-management-heading{
    color: #00549E;

}

.CallHistoryTable-CM-Body tr td{
    font-weight: normal ;
}



.title-large-disposition-call-management{
    font-size: 20px;
}

.cm-last-table-header *{
    font-weight: bold !important;
    color: black !important;
    font-size: 16px !important;
}

.call-buttons-call-management *{
    margin: 10px;
}

.call-management-columsn-loan-update{
    background-color:  rgba(217, 217, 217, 0.3);;
}


@media (min-width:768px) and (max-width: 1300px){
    .d-flex{
        display: flex !important;
    }

    .borrowerProfileData{
       display: grid !important;
    }
    
    .disposition-class-CM{
        width: 100px !important;
    }

    .disposition-code-CM{
        width: 100px !important;
    }

    .top-box-col-call-management{
        width: 15%;
    }



    .third-box-call-management{
        display: grid !important;
    }

    .firstHalf-mobile{
        width: 100% !important;
    }

    
    .loan_Number_box-call-management{
        margin-left: 0 !important;
    }

    .first-half-calling-call-management{
        width: 70% !important;
        max-height: 300px !important;
    }

    .mobile-radio-select{
        display: grid !important;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto;
        
    }
    .outer-call-buttons-holder-call-management{
        display: flex !important;
        justify-content: center !important;
    }
    .call-buttons-call-management{
        display: flex !important;
        width: 50% !important;
        align-items: center;
        justify-content: space-between;
    }
   

}

.classButton{
    font-family: 'Inter' !important;
    font-style: normal;
    background: #00549E;
    color: white !important;
    text-decoration-color: white !important;
    font-weight: 500;
    font-size: 18.7686px !important;
    /* line-height: 25px; */
    padding: -0.00rem !important;
    height: 33px;
    margin-top: 5% !important;
    align-items: center;
    text-align: center;
}


input{
    text-indent: 10px;
  }
.CustomClass th{
    background-color: #A6A8AA;
    color: #fff !important;
    text-decoration-color: #222222 !important;
    font-family: 'Inter' !important;
    font-style: bold;
    font-size: 40px;
    line-height: 21px;
    line-height: 100%;
    text-align: left;
    vertical-align: top;
    height: 50px;
}


/**
Width
126px
Height
56px
Padding
16px, 0px, 16px, 0px
*/
.CustomBodyClass tr{
    width: 150px;
    height: 40px;
    padding: 16px, 0px, 16px, 0px;
    /* border-bottom: 1px inset #c6c7c7; */
    /* border-top: 1px inset #c6c7c7; */
    /* box-shadow: 0px 2px 2px rgb(132, 131, 131); */
    /* -moz-box-shadow: 0px 2px 2px rgb(132, 131, 131);; */
    /* -webkit-box-shadow: 0px 2px 2px rgb(132, 131, 131);; */

}


.CustomBodyClass tr td{
    text-align: left;
    align-items: left;
}



.setAgentProfile{
    font-family: 'Inter' !important;
font-style: bold;
font-weight: 700;
font-size: 22px;
line-height: 24px;

color: #00549E !important;
}

.setTextStyleRegular{
    font-family: 'Inter' !important;
font-style: normal;
font-weight: 200;
font-size: 16px;
line-height: 20px;

color: #000000 !important;
}

.setPlaceHolderRegular{
    font-family: 'Inter' !important;
font-style: normal;
font-weight: 200;
font-size: 14px;
line-height: 20px;
color: #575757 !important;
}

.setTextStyleBold{
    font-family: 'Inter' !important;
font-style: bold;
font-weight: 200;
font-size: 14px;
line-height: 20px;

color: #575757 !important;
}

.CustomBodyClass{
    text-align: left;
    /* box-shadow: #090909; */
    color: #222222 !important;
}
.CustomBodyClass tr td{
    color: #222222 ;
    font: 'Inter' !important;
    size:16px;
    line-height:21.09px;
}
 
.CustomBodyClass-CM tr td{
    width: 141.97px;
    height: 20px;   
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    color: #222222;
}

table.table td {
    font-size: 13px;
    color: #222222;
    font-weight: 600;
    border: none !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-family: 'Inter' !important;
}


.setColor{
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
    color: #222222;
}

.btn{
    text-transform: capitalize;
}

.title{
    color: #00549E;
    font-size: 24px;
    font-weight: 700;

}

.setgap{
    row-gap: 1rem;
}

.table-dark{
    background-color: #A6A8AA !important;
}


.font-loanDetails{
    font-weight: 700;
}


.CustomClass{
    padding-bottom: 40px !important;
}

.CustomBodyClass tr:nth-child(even) {
    background-color: #f5f5f5;
}

.CustomClass-CM th{
    width: 87.44px !important;
height: 40px !important;
font-style: normal !important;
font-weight: 700 !important;
font-size: 17px !important;
line-height: 20px !important;
}

*{
    font-family: sans-serif !important;
}

.form-control{
    width: 100% !important;
}

thead > tr > th{
    vertical-align: middle !important;
}

.my-table {
    box-shadow: none;
}

label {
    margin-bottom: .5rem;
    display: inline-block;
    width: 100%;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #D9D9D9;
    border-radius: 2px;

}

::-webkit-scrollbar {
    width: 8px;
    height: 15px;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 3px;
}


.search-button-wrapper {
    margin-left: 20px; /* or adjust the value to your liking */
  }

table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: none;
}

table.table th {
    font-size: 13px;
    font-family: "Inter"  !important;
    font-weight: 600;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
    

.react-bootstrap-table-pagination-total {
    position: relative;
    top: 8px;
}

.col-3 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 27px;
    padding-left: 0px;
    flex: 0 0 25%;
    max-width: 35%;
}

.form-control-sm {
    padding: .25rem .5rem;
    font-size: .76563rem;
    line-height: 1.5;
    border-radius: .2rem;
    min-height: 30px;
}

.form-control-sm-drop-down {
    padding: .25rem 1.5rem;
    font-size: .76563rem;
    line-height: 1.5;
    border-radius: .2rem;
    min-height: 25px;
    height: 28px;
}

select {
    -webkit-appearance: none;
    appearance: none;
}

select {
    background-image: url("/src/caret-down-light.svg");
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .3rem;
    color: #222222;
    content: ">";
    font-weight: 400;
}

.table-striped tbody tr:nth-of-type(odd) {
    background: #ffffff;
}
