.header-style-1 {
  background: #d9d9d9 !important;
  background-image: none !important;
  padding: 0px 0 1px;
  .nav-style-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-brand {
      margin-left: 20px;
      padding-top: 0;
      padding-bottom: 0;
      img {
        height: 50px;
        display: inline-block;
        margin-right: 5px;
      }
    }
    .right-header {
      margin-right: 20px;
      .nav-link {
        padding: 0;
        text-align: right;
      }
      .profile-pic {
        width: 40px;
        border-radius: 50%;
      }
      .user-detail {
        letter-spacing: 0.055em;
        font-weight: 400;
        font-size: 15px;
        line-height: 40px;
        color: #575757;
        margin-top: 5px;
        margin-right: 10px;
        font-family: "Roboto", sans-serif;
        span:last-child {
          color: #000;
        }
      }
    }
  }
}

//Custom switch

.custom-control1 {
  z-index: 1000;
}

.custom-switch {
  padding-left: 2.25rem;
  position: relative;
  display: block;
  min-height: 1.5rem;
}
.custom-control-input1 {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-switch .custom-control-label1 {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #212529;
  margin-left: 7px;
  cursor: pointer;
}
.custom-control-label1 span {
  display: inline-block;
  margin-left: 20px;
  margin-top: 5px;
}
.custom-control-label1::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 20px;
  pointer-events: none;
  content: "";
  background-color: #d8dae5;
  border: #d8dae5 solid 1px;
}
.custom-control-label1::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.custom-switch .custom-control-label1::before {
  left: -2.95rem;
  width: 40px;
  pointer-events: all;
  border-radius: 0.7rem;
}
.custom-control-label1::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-switch .custom-control-label1::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.85rem + 2px);
  width: calc(1rem);
  height: calc(1rem);
  background-color: #fff;
  border-radius: 0.7rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
.custom-control-input1:checked ~ .custom-control-label1::before {
  color: #00549e;
  border-color: #00549e;
  background-color: #00549e;
}
.custom-switch .custom-control-input1:checked ~ .custom-control-label1::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
  left: calc(-2.85rem + 9px);
}

// dropdown
.btn-dropdown {
  // width: 100px;
  cursor: pointer;
  position: relative;
  // margin-right: 4rem;
}

.css-yk16xz-control {
  cursor: pointer;
}

.btn-drop {
  cursor: pointer;
}

.custom-ready-btn {
  border-radius: 1.5rem;
  padding: 0.4rem 1.54rem;
  font-weight: bold;
  // font-size: 1rem;
}

.dropdown-menu-status {
  position: absolute;
  background-color: white;
  padding: 0.5rem;
  top: 130%;
  color: black;
  font-weight: 600;
  border-radius: 5px;
  width: 150px;
  border: 1px solid lightgray;
}

.dropdown-option {
  border-radius: 4px;
  padding: 0.5rem;
  &:hover {
    background-color: #cfebfd;
  }
}

.custom-button-status {
  cursor: pointer;
  border-radius: 1.5rem;
  border: 1.5px solid red;
  color: red;
  font-weight: bold;
  padding: 0 0.7rem 0 1rem;
}
