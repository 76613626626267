.setAgentProfile {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #00549E !important;
}

.setColor {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
}

.setAgentProfile {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #00549E !important;
}
.call_mage_label2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16.7686px;
  line-height: 25px;
  text-align: left;
  display: block;
  margin-bottom: 2px;
  margin-top: -14px
}

.form-control {
  width: 100% !important;
}
.marginClass{
  margin-top: 7%;
}
.classButton {
  font-family: sans-serif;
  font-style: normal;
  background: #00549E;
  color: white !important;
  width: 98.5%;
  margin-left: 1%;
  height: 36px !important;
	border-radius: 4px 4px !important;
  text-decoration-color: white !important;
  font-weight: 700 !important;
  font-size: 18.7686px !important;
  line-height: 25px;
  padding: -0.00rem !important;
  margin-top: 6% !important;
  align-items: center;
  text-align: center;
  text-transform: capitalize !important;

}
.dateFrom{
  width: 98%;
}
.outlineColor {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18.7686px;
  height: 36px !important;
  color: #00549E !important;
	border-radius: 4px 4px !important;
  background-color: #ffffff !important;
  border: 2px solid #00549E !important;
  line-height: 25px;
  padding: -0.00rem !important;
  margin-top: 6% !important;
  text-transform: capitalize;
  align-items: center;
  text-align: center;
}

.CustomClass {
  background-color: rgb(239, 238, 238);
  width: 126px;
  height: 50px;
  padding: 16px, 0px, 16px, 0px;
  margin-top: -5px;
  margin-bottom: 100px;
  text-transform: uppercase !important;
  padding-bottom: 40px !important;
}
.CustomClass th {
  color: #ffffff !important;
  background-color: #808184;
  text-decoration-color: #222222 !important;
  font-family: sans-serif;
  font-style: bold;
  font-size: 40px;
  line-height: 21px;
  line-height: 100%;
  text-align: left;
  vertical-align: top;
}

.CustomBodyClass tr {
  width: 150px;
  height: 56px;
  padding: 16px, 0px, 16px, 0px;  
  background-color: #ffffff !important;
  td {
      text-align: start !important;
  }
  // font-family: 'Roboto';
}

.CustomBodyClass tr:nth-child(even) {
  background-color: #F5F5F5  !important;
}

.CustomBodyClass tbody tr td {
  color: #282727;
  font-family: sans-serif;
  font-style: Regular;
  font-size: 18px;
  line-height: 21px;
  line-height: 100%;
  align-items: Left;
  vertical-align: top;
}

.CustomBodyClass tr td {
  text-align: left;
  align-items: left;
}

.CustomBodyClass {
  text-align: left;
  color: #222222 !important;
}

.CustomBodyClass tr td {
  color: #222222;
  // font: Roboto;
  font-weight: 519;
  size: 18px;
  line-height: 21.09px;
}
.btn2 {
  padding: 0.1rem 1.39rem !important;
  border-radius: 4rem !important;
  background-color: #FFFFFF;
  color: #00549E;
  font-weight: 400 !important;
  font-family: "inter";
  font-size: 13px;
  font-stretch: expanded;
}

.btn2:focus {
  border: 1px solid #00549E;
}

.line {
  width: "10px";
  background-color: #D9D9D9;
  margin-right: 25px;
}

hr {
  flex: 1;
  border: none;
  height: 1px;
  background: black;
}
select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../DownArrow.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 11px) center;
}
input[type=date]::-webkit-calendar-picker-indicator{
     color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../../calender-icon.svg") no-repeat;
  width: 12px;
  background-size: 12px;
  background-position: calc(100% - 3px) center;
  height: 16px;
  border-width: thin;
}
::-webkit-input-placeholder {
  opacity:  0.4 !important;
}
input[type=date]::-webkit-datetime-edit-text,
input[type=date]::-webkit-datetime-edit-month-field,
input[type=date]::-webkit-datetime-edit-day-field,
input[type=date]::-webkit-datetime-edit-year-field {
  opacity: 0.4;
}
input[type=date].date-input--has-value::-webkit-datetime-edit-text,
input[type=date].date-input--has-value::-webkit-datetime-edit-month-field,
input[type=date].date-input--has-value::-webkit-datetime-edit-day-field,
input[type=date].date-input--has-value::-webkit-datetime-edit-year-field {
opacity: 0.4;
}
.react-bs-table-sizePerPage-dropdown {
  visibility: hidden !important;
}
thead>tr>th {
  vertical-align: middle !important;
}
.bg-sidemenu {
  background-color: #DADADA;
}
.active-loan-table {
  margin-top: 0px !important;
}