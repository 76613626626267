.left-sidebar.crm{
    .sidebar-nav > ul > li {
        a{
            text-align: left !important;
            padding-left: 24px !important;
            img{
                margin-right: 15px;
                display: inline-block;
            }
        }
        &.active{
            a{
                background: #F3F3F3;
            }
        }
    }
} 
.left-sidebar .sidebar-nav{
    background: #e1e1e1 !important;
}
.card-new-1{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3), 0 2px 10px 0 rgba(0,0,0,0.3);
}